<template>
    <div class="ServiceCenter">
        <!-- <h1>服务中心</h1> -->
        <img src="https://img.youstarplanet.com/website/serviceCenter/ServiceCenter.png" alt="">
        <div class="content">
            <div class="service_content">
                <div>服务内容</div>
                <div>产业平台，技术创新，一点接入，全链无忧</div>
                <div>
                    <div>
                        <img src="https://img.youstarplanet.com/website/serviceCenter/service_content1.svg" alt="">
                        <div>
                            <span>服务演艺文化行业上中下游板块</span>
                            <span>去产品库存，拓收入渠道</span>
                            <span>1.项目包装、商务代理</span>
                            <span>2.版权管理、商业变现</span>
                            <span>3.数字产品开发</span>
                            <span>4.信息数据服务</span>
                        </div>
                    </div>
                    <div>
                        <img src="https://img.youstarplanet.com/website/serviceCenter/service_content2.svg" alt="">
                        <div>
                            <span>不断满足各方演艺文化内容需求</span>
                            <span>省时、省力、省钱、省心</span>
                            <span>1.资源高效配置、信息公开透明</span>
                            <span>2.专业、专一、专注，提供体系化解决方案</span>
                            <span>3.去中间环节，以资源体系优势降低边际成本</span>
                            <span>4.结果导向，体系化运作，高行业服务标准</span>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <img src="https://img.youstarplanet.com/website/serviceCenter/service_content3.svg" alt="">
                        <div>
                            <span>健全社会公共文化服务体系</span>
                            <span>创新基层公共文化服务方式</span>
                            <span>1.基层数据调研</span>
                            <span>2.打造头部文化品牌，塑造城市文化名片</span>
                            <span>3.提供一揽子解决方案</span>
                        </div>
                    </div>
                    <div>
                        <img src="https://img.youstarplanet.com/website/serviceCenter/service_content4.svg" alt="">
                        <div>
                            <span>不断满足大众娱乐及文化消费需求</span>
                            <span>保障人人享有基本文化生活权利</span>
                            <span>1.从无到有、从有到优</span>
                            <span>2.品类丰富、价格实惠</span>
                            <span>3.平台化、数字化、常态化</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="service_process">
                <img src="https://img.youstarplanet.com/website/serviceCenter/service_process.png" alt="">
            </div>
            <div class="project_application">
                <div>项目发布</div>
                <div>
                    <img src="https://img.youstarplanet.com/website/serviceCenter/project_application.png" alt="">
                    <a href="">提交资料</a>
                </div>
            </div>
            <div class="need_to_register">
                <div>需求登记</div>
                <div>
                    <img src="https://img.youstarplanet.com/website/serviceCenter/need_to_register.png" alt="">
                    <a href="">提交资料</a>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
export default{
    data() {
        return {}
    },
    async mounted() {
        document.documentElement.scrollTop = 0
    },
}
</script>

<style lang="less">
.ServiceCenter{
    position: relative;
    &>img{
        width: 100%;
        object-fit:cover;
    }
    .content{
        width: 1270px;
        margin: 0 auto;
        .service_content{
            margin-top: 88px;
            margin-bottom: 200px;
            padding: 0 71px;
            box-sizing: border-box;
            &>div{
                &:nth-child(1){
                    font-size: 28px;
                    text-align: center;
                }
                &:nth-child(2){
                    margin-top: 36px;
                    font-size: 18px;
                    text-align: center;
                }
                &:nth-child(3){
                    margin-top: 46px;
                    display: flex;
                    justify-content: space-between;
                    &>div{
                        background-color: #FBFBFB;
                        width: 550px;
                        height: 268px;
                        padding: 54px 47px 30px 47px;
                        box-sizing: border-box;
                        display: flex;
                        img{
                            width: 54px;
                            height: 54px;
                            margin-right: 35px;
                        }
                        div{
                            display: flex;
                            flex-direction: column;
                            span{
                                font-size: 14px;
                                color: #848484;
                                &:nth-child(1){
                                    font-size: 18px;
                                    color: #000;
                                }
                                &:nth-child(2){
                                    font-size: 16px;
                                    font-style: italic;
                                    margin: 17px 0 25px 0;
                                    color: #000;
                                }
                            }
                        }
                    }
                }
                &:nth-child(4){
                    margin-top: 21px;
                    display: flex;
                    justify-content: space-between;
                    &>div{
                        background-color: #FBFBFB;
                        width: 550px;
                        height: 268px;
                        padding: 54px 47px 30px 47px;
                        box-sizing: border-box;
                        display: flex;
                        img{
                            width: 54px;
                            height: 54px;
                            margin-right: 35px;
                        }
                        div{
                            display: flex;
                            flex-direction: column;
                            span{
                                font-size: 14px;
                                color: #848484;
                                &:nth-child(1){
                                    font-size: 18px;
                                    color: #000;
                                }
                                &:nth-child(2){
                                    font-size: 16px;
                                    font-style: italic;
                                    margin: 17px 0 25px 0;
                                    color: #000;
                                }
                            }
                        }
                    }
                }
            }
        }
        .service_process{
            margin-top: 71px;
        }
        .project_application{
            margin-top: 106px;
            &>div{
                &:nth-child(1){
                    text-align: center;
                    font-size: 28px;
                }
                &:nth-child(2){
                    margin-top: 45px;
                    position: relative;
                    img{
                        width: 100%;
                        height: 345px;
                    }
                    a{
                        position: absolute;
                        left: 515px;
                        top: 151px;
                        width: 240px;
                        height: 44px;
                        line-height: 44px;
                        text-align: center;
                        border-radius: 50px;
                        font-size: 18px;
                        color: #FFFFFF;
                        background-color: #1990FF;
                    }
                }
            }
        }
        .need_to_register{
            margin-top: 106px;
            margin-bottom: 200px;
            &>div{
                &:nth-child(1){
                    text-align: center;
                    font-size: 28px;
                }
                &:nth-child(2){
                    margin-top: 45px;
                    position: relative;
                    img{
                        width: 100%;
                        height: 345px;
                    }
                    a{
                        position: absolute;
                        left: 515px;
                        top: 151px;
                        width: 240px;
                        height: 44px;
                        line-height: 44px;
                        text-align: center;
                        border-radius: 50px;
                        font-size: 18px;
                        color: #FFFFFF;
                        background-color: #F4397E;
                    }
                }
            }
        }
    }
}
</style>


